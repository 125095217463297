import {KnowledgeSection} from '../types/knowledge-section.type'
import {parseCallToAction} from './call-to-action.serde'
import {KnowledgeSectionFieldsFragment} from '../../../../__generated__/datocms.types'
import {validateDatoCmsTypes} from './common.serde'
import {parseAccordion} from './accordion.serde'

export function parseKnowledgeSection(k: KnowledgeSectionFieldsFragment): KnowledgeSection {
  const section = validateDatoCmsTypes(k)

  return {
    __type: 'KnowledgeSection',
    id: section.id,
    title: section.title!,
    plot: section.plot!,
    text: section.text!,
    cta: parseCallToAction(section.cta!),
    faq: parseAccordion(section.faq!),
  }
}
