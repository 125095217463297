import {ChangeDetectionStrategy, Component, Input} from '@angular/core'
import {KnowledgeSection} from '../../../../core/content/types/knowledge-section.type'
import {SupportService} from '../../../../core/support/support.service'
import {ButtonModule} from '../../../../craft/button/button.module'
import {SupportPortraitComponent} from '../../../../craft/support/support-portrait/support-portrait.component'
import {SupportBoxModule} from '../../../../craft/support/support-box/support-box.module'
import {AsyncPipe} from '@angular/common'
import {RichTextModule} from '../../rich-text/rich-text.module'

@Component({
  selector: 'bgo-knowledge-section',
  templateUrl: './knowledge-section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ButtonModule, SupportPortraitComponent, SupportBoxModule, AsyncPipe, RichTextModule],
  styles: [
    `
      :host {
        @apply block;
      }
    `,
  ],
})
export class KnowledgeSectionComponent {
  @Input() knowledgeSection!: KnowledgeSection

  constructor(protected readonly supportService: SupportService) {}
}
